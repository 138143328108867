export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDEaTUSlyjwNTWMuf4UpyvVu_9zcT3vvac",
    authDomain: "marcairis-business.firebaseapp.com",
    databaseURL: "https://marcairis-business-default-rtdb.firebaseio.com",
    projectId: "marcairis-business",
    storageBucket: "marcairis-business.appspot.com",
    messagingSenderId: "662058834642",
    appId: "1:662058834642:web:555ae734d09109a8352b0b",
    measurementId: "G-PQTHW1TP5C"
  }
};
