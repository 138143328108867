import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any; // Save logged in user data

  private auth = getAuth();
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  constructor(public router: Router, public ngZone: NgZone, private cookieService: CookieService ) { 
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  signOut() {
    this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.cookieService.delete('Marcairis-Accounts-S-Id');
      this.Toast.fire({
        icon: 'success',
        title: "¡Haz cerrado sesión con éxito!",
        text: "Esperamos que vuelvas pronto :)"
      });
      this.ngZone.run(() => {
        this.router.navigate(['/']);
      });
    }).catch((e) => {
      this.Toast.fire({
        icon: 'error',
        title: "¡Ha ocurrido un error!",
        text: "Vuelve a intentarlo en unos momentos..."
      });
    });
  }
  signOutPath(from, pathname) {
    this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.cookieService.delete('Marcairis-Accounts-S-Id');
      this.Toast.fire({
        icon: 'success',
        title: "¡Haz cerrado sesión con éxito!",
        text: "Esperamos que vuelvas pronto :)"
      });
      if(pathname)
        window.location.href = from + "/"+ pathname;
      else
        window.location.href = from + "/";
    }).catch((e) => {
      this.Toast.fire({
        icon: 'error',
        title: "¡Ha ocurrido un error!",
        text: "Vuelve a intentarlo en unos momentos..."
      });
    });
  }
  disabled() {
    this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.cookieService.delete('Marcairis-Accounts-S-Id');
      this.ngZone.run(() => {
        this.router.navigate(['/']);
      });
    }).catch((e) => {
      this.Toast.fire({
        icon: 'error',
        title: "¡Ha ocurrido un error!",
        text: "Vuelve a intentarlo en unos momentos..."
      });
    });
  }
}
