import { Component } from '@angular/core';
import { initializeApp } from "firebase/app"
import { environment } from 'src/environments/environment.prod';
import { Storage } from '@ionic/storage';
import { getDatabase } from 'firebase/database';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private storage: Storage) {
    this.storage.create();
    const firebaseApp = initializeApp(environment.firebaseConfig);
    const database = getDatabase(firebaseApp);
  }
}
