import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private userService: UserService){ }

  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      const auth = getAuth();
      return new Promise((resolve, reject) => {
        auth.onAuthStateChanged(async (user) => {
          if(user){
            await onSnapshot(this.userService.getUserDocId(user.uid), async (snap) => {
              if(snap.exists && snap.id && snap.data()['deshabilitado'] && snap.data()['deshabilitadoFecha'].toDate() > new Date()){
                await this.authService.disabled();
                this.Toast.fire({ icon: 'error', title: "¡Lo sentimos!", text: "Tu usuario se encuentra deshabilitado" });
                resolve(false);
              }else{
                resolve(true);
              }
            });
          }
        });
      });
            
  }
}
