import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { getAuth } from '@firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecureGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private acRoute: ActivatedRoute, private userService: UserService){ }

  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const auth = getAuth();
      var from = "";
      return new Promise((resolve, reject) => {
        auth.onAuthStateChanged(async (user) => {
          if(user){
            await onSnapshot(this.userService.getUserDocId(user.uid), async (snap) => {
              if(snap.data()['deshabilitado'] && ((snap.data()['deshabilitadoFecha'] && snap.data()['deshabilitadoFecha'].toDate() > new Date()) || !snap.data()['deshabilitadoFecha'])){
                await this.authService.disabled();
                this.Toast.fire({ icon: 'error', title: "¡Lo sentimos!", text: "Tu usuario se encuentra deshabilitado" });
                resolve(false);
              }
              if(!user.emailVerified && user.email !== null){
                resolve(true);
              }
              else if(user.phoneNumber == null){
                resolve(true);
              }else{
                const parsedUrl = new URL(window.location.href);
                if(parsedUrl.search){
                  from = (parsedUrl.search).split("=")[1];
                  resolve(true);
                }else{
                  this.router.navigate(['/my-account/']); 
                }
              }
            })
          }else{
            resolve(true);
          }
        }, (error) => {
          console.error(error);
          this.router.navigate(['../'])
        })
      })
  }
}
