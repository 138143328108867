import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SecureGuard } from './guards/secure/secure.guard';

const routes: Routes = [  
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [SecureGuard]
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule),
    canActivate: [AuthGuard]
  },
  { path: '404', redirectTo: 'login'},
  { path: '**', redirectTo: 'login'},
  {
    path: 'view-user',
    loadChildren: () => import('./modals/view-user/view-user.module').then( m => m.ViewUserPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modals/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'edit-user',
    loadChildren: () => import('./modals/edit-user/edit-user.module').then( m => m.EditUserPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./modals/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./modals/services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },  {
    path: 'mailer',
    loadChildren: () => import('./modals/mailer/mailer.module').then( m => m.MailerPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
