import { Injectable, NgZone } from '@angular/core';
import { getAuth } from "firebase/auth";
import Swal from 'sweetalert2';
import { doc, setDoc, getDoc, where, query, collection, getDocs, limit, deleteDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals';
import { Router } from '@angular/router';

interface user {
  email: string;
  uid: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: user;
  private auth = getAuth();
  private db = getFirestore();
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  constructor(private globals: Globals, private http: HttpClient, private ngZone: NgZone, private router: Router) { }

  setUser(user: user) {
    this.user = user;
  }

  getClicks(){
    return getDocs(query(collection(this.db, "clicks")));
  }
  
  getRanks() {
    return [{ id: 1, name: "Admin" }, { id: 2, name: "Usuario Master" }, { id: 3, name: "Usuario Avanzado" }, { id: 4, name: "Usuario Básico" }];
  }


  getUserDocId(idUser: string) {
    return doc(this.db, 'usuarios', idUser);
  }
  
  getUsers(){
    return query(collection(this.db, "usuarios"));
  }
  
  getPlanes(){
    return query(collection(this.db, "planes"));
  }

  getUsersFavs(){
    return getDocs(query(collection(this.db, "usuarios"), where("productosFav", "!=", null)));
  }
  getUsersR(){
    return getDocs(query(collection(this.db, "usuarios"), where("tipo", ">", 0)));
  }
  async updateUserInfo(record){
    await setDoc(doc(this.db, "usuarios", record['id']), record, { merge: true })
  }
  async updateUserInfoByDocId(record){
    await setDoc(doc(this.db, "usuarios", record['docId']), record, { merge: true })
  }
  async disableUser(id){
    await setDoc(doc(this.db, "usuarios", id), {deshabilitado: true, habilitado: false}, { merge: true });
  }
  async setUserInfo(record) {
    await setDoc(doc(this.db, "usuarios", record['id']), record, { merge: true });
  }
  async deleteAccount(id){
    return await setDoc(doc(this.db, "usuarios", id), { deshabilitado: true, deshabilitadoFecha: new Date("2999-01-01") }, { merge: true });
  }
  async disableAccount(id, date){
    return await setDoc(doc(this.db, "usuarios", id), { deshabilitado: true, deshabilitadoFecha: new Date(date) }, { merge: true });
  }

  async enableAccount(id){
    return await setDoc(doc(this.db, "usuarios", id), { deshabilitado: false, deshabilitadoFecha: null }, { merge: true });
  }
  addFriend(docId: string, friendId: string) {
    /*this.afs
      .collection('usuarios')
      .doc(docId)
      .update({
        contactos: firebase.default.firestore.FieldValue.arrayUnion(friendId),
        solicitudes:
          firebase.default.firestore.FieldValue.arrayRemove(friendId),
      });
    this.afs
      .collection('usuarios')
      .doc(friendId)
      .update({
        contactos: firebase.default.firestore.FieldValue.arrayUnion(docId),
        solicitudes: firebase.default.firestore.FieldValue.arrayRemove(docId),
      });*/
  }
  sendFriendReq(docId: string, friendId: string) {
    /*this.afs
      .collection('usuarios')
      .doc(friendId)
      .update({
        solicitudes: firebase.default.firestore.FieldValue.arrayUnion(docId),
      });*/
  }
  removeFrRq(docId: string, friendId: string) {
    /*this.afs
      .collection('usuarios')
      .doc(docId)
      .update({
        solicitudes:
          firebase.default.firestore.FieldValue.arrayRemove(friendId),
      });*/
  }
  async userByDoc(uid) {
    return await getDoc(doc(this.db, "usuarios", uid));
  }
  async getLists() {
    return await getDocs(collection(this.db, "listadeseos"));
  }
  getUserByIdOnChanges(uid){
    return query(collection(this.db, "usuarios"), where("id", "==", uid), limit(1));
  }
  getQueryCC(uid){
    return query(collection(this.db, "tarjetascd"), where("id", "==", uid), limit(1));
  }
  async setCC(record) {
    await setDoc(doc(this.db, "tarjetascd",  record['id']), record);
  }
  getUserByPar(by, value) {
    return getDocs(query(collection(this.db, "usuarios"), where(by, "==", value), limit(1)));
  }
  getUsersByPar(by, value) {
    return getDocs(query(collection(this.db, "usuarios"), where(by, "==", value)));
  }
  getUID(){
    return this.auth.currentUser.uid;
  }
  logInTo(from, pathname){
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    this.http.post(this.globals.API_BACKEND + "generateToken", { "userId" : this.auth.currentUser.uid }, { headers: headers }).subscribe((data) => {
      var tokenTemp = data["token"];
      if(pathname)
        window.location.href = from + "/authorize/" + tokenTemp + "?pathname="+ pathname;
      else
        window.location.href = from + "/authorize/" + tokenTemp;
    });
  }
  goHome(){
    this.ngZone.run(() => {
      this.router.navigate(["/my-account/index"]);
    });
  }
  signOut() {
    this.auth.signOut().then(() => {
      this.Toast.fire({
        icon: 'success',
        title: "¡Haz cerrado sesión con éxito!",
        text: "Esperamos que vuelvas pronto :)"
      });
      this.ngZone.run(() => {
        this.router.navigate(['/']);
      });
    }).catch((e) => {
      this.Toast.fire({
        icon: 'error',
        title: "¡Ha ocurrido un error!",
        text: "Vuelve a intentarlo en unos momentos..."
      });
    });
  }
}
